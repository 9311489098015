import { Box, Dialog, DialogContent, Stack, Typography } from "@mui/material";
import { IDataOfNumberOfVisitorsByCountry, IDataOfNumberOfVisitors } from "../../utils/interfaces";

/* ---------------------------------------------------------------------- */

interface IProps {
  isOpened: boolean;
  setIsOpened: Function;
  data: IDataOfNumberOfVisitors;
}

/* ---------------------------------------------------------------------- */


export default function DialogOfVisitors({ isOpened, setIsOpened, data }: IProps) {

  const handleClose = () => {
    setIsOpened(false)
  }

  return (
    <Dialog onClose={handleClose} open={isOpened} fullWidth maxWidth="xs">
      <DialogContent sx={{ px: 4 }}>
        <Stack spacing={2} py={2}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography component="span" fontWeight={900}>Total:</Typography>
            <Typography component="span">{data.total}</Typography>
          </Stack>
          <Box height={2} bgcolor="white" />
          <Stack spacing={1}>
            {data.data.map((dataItem: IDataOfNumberOfVisitorsByCountry) => (
              <Stack direction="row" alignItems="center" justifyContent="space-between" key={dataItem.id}>
                <Typography component="span" fontWeight={700}>{dataItem.country_code}</Typography>
                <Typography component="span">{dataItem.number_of_visitors}</Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}