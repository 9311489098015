import { createContext, useContext, useReducer } from 'react';
import { ICardData } from '../utils/interfaces';
import { LoadingContext } from './LoadingContext';

/* --------------------------------------------------------------- */

interface IInitialState {
  cards: Array<ICardData>;
  card: ICardData | null;
}

interface IAction {
  type: string,
  payload: any
}

interface IProps {
  children: any
}

interface IHandlers {
  [key: string]: Function,
}

/* --------------------------------------------------------------- */

const initialState: IInitialState = {
  cards: [],
  card: null,
};

const handlers: IHandlers = {
  SET_CARD: (state: object, action: IAction) => {
    return {
      ...state,
      card: action.payload
    };
  },
  SET_CARDS: (state: object, action: IAction) => {
    return {
      ...state,
      cards: action.payload
    };
  },
};

const reducer = (state: object, action: IAction) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

//  Context
const CardsContext = createContext({
  ...initialState,
  getCardByIdAct: (id: number) => Promise.resolve(),
  clearOrderAct: () => Promise.resolve(),
  setCardsAct: (cards: Array<ICardData>) => Promise.resolve(),
});

//  Provider
function CardsProvider({ children }: IProps) {
  const { openLoading, closeLoading } = useContext(LoadingContext);

  const [state, dispatch] = useReducer(reducer, initialState);

  const setCardsAct = (cards: Array<ICardData>) => {
    dispatch({
      type: 'SET_CARDS',
      payload: cards,
    })
  }

  const getCardByIdAct = (id: number) => {
    openLoading()
    const card = state.cards.find((cardItem: ICardData) => cardItem.id === id)
    dispatch({
      type: 'SET_CARD',
      payload: card || null
    })
    closeLoading()
  }

  const clearOrderAct = () => {
    dispatch({
      type: 'SET_CARD',
      payload: null
    })
  }

  return (
    <CardsContext.Provider
      value={{
        ...state,
        getCardByIdAct,
        clearOrderAct,
        setCardsAct,
      }}
    >
      {children}
    </CardsContext.Provider>
  );
}

export { CardsContext, CardsProvider };