import { useRoutes } from 'react-router';
import { Navigate } from "react-router-dom";
import useUser from '../hooks/useUser';
import DashboardLayout from '../layouts/DashboardLayout';
import Dashboard from '../pages/Dashboard';
import Login from '../pages/Login';
import Signup from '../pages/Signup';

export default function Routes() {
  const { token } = useUser()
  return useRoutes([
    {
      path: '/',
      children: [
        {
          path: '',
          element: token ? <Navigate to="/login" /> : <Navigate to="/dashboard" />
        },
        {
          path: 'dashboard',
          element: <DashboardLayout />,
          children: [
            {
              path: '',
              element: <Dashboard />
            }
          ]
        },
        {
          path: 'login',
          element: <Login />
        },
        {
          path: 'signup',
          element: <Signup />
        },
        {
          path: '*',
          element: token ? <Navigate to="/login" /> : <Navigate to="/dashboard" />
        },
      ]
    },
  ])
}