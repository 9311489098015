import { Box, Button, Card, CardContent, CardHeader, Grid, Stack, TextField } from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAlertMessage from "../hooks/useAlertMessage";
import useLoading from "../hooks/useLoading";
import useUser from "../hooks/useUser";
import api from "../utils/api";
import { ERROR, INFO, MESSAGE_FILL_IN_ALL_VALUES, MESSAGE_LOGIN_SUCCESS, SUCCESS } from "../utils/constants";

export default function Signup() {
  const navigate = useNavigate()
  const { setTokenAct } = useUser()
  const { openLoading, closeLoading } = useLoading()
  const { openAlert } = useAlertMessage()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [adminPassword, setAdminPassword] = useState('')

  const handleSubmit = () => {
    if (firstName && lastName && email && password && adminPassword) {
      openLoading()
      api.post('/admin/signup', { firstName, lastName, email, password, adminPassword })
        .then(response => {
          if (response.data) {
            setTokenAct(response.data)
            closeLoading()
            openAlert({ severity: SUCCESS, message: MESSAGE_LOGIN_SUCCESS })
            navigate('/dashboard')
          }
        })
        .catch(error => {
          if (error?.response) {
            if (error.response.status === 400) {
              openAlert({
                severity: INFO,
                message: "Already registered. Please login."
              })
            } else if (error.response.status === 401) {
              openAlert({
                severity: ERROR,
                message: "The password for administrators is incorrect."
              })
            }
          } else {
            openAlert({
              severity: ERROR,
              message: error?.response?.statusText || 'Server error.'
            })
          }
          closeLoading()
        })
    } else {
      alert(MESSAGE_FILL_IN_ALL_VALUES)
    }
  }

  return (
    <Stack height="100vh" justifyContent="center" alignItems="center">
      <Card sx={{ width: { xs: '90%', sm: '50%', md: '30%' } }}>
        <CardHeader
          title="Sign up"
          titleTypographyProps={{ fontWeight: 800 }}
        />
        <CardContent sx={{ p: 3 }}>
          <Stack spacing={3}>
            <Box>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    label="First name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    label="Last name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>
            <TextField
              type="email"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
            />
            <TextField
              type="password"
              label="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
            />
            <TextField
              type="password"
              label="Common Password for administrators"
              value={adminPassword}
              onChange={(e) => setAdminPassword(e.target.value)}
              fullWidth
            />
            <Stack direction="row" justifyContent="space-between">
              <Button
                variant="outlined"
                component={Link}
                to="/login"
              >Login</Button>
              <Button variant="contained" onClick={handleSubmit}>Signup</Button>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  )
}